import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import sessionStorage from '@mc/storage/session';

const STAGES = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

/**
 * By default we don't send bugsnags from dev. Enable overriding this behavior based on
 * a session storage key value pair. Engineers can toggle this value temporarily within
 * the settings pane of the admin tool bar.
 *
 * @returns array
 */
const getEnabledStages = () => {
  const stages = [STAGES.PRODUCTION];

  if (__DEV__) {
    if (sessionStorage.getItem('dev-tools:enable-bugsnag-dev') === 'true') {
      stages.push(STAGES.DEVELOPMENT);
    }
  }

  return stages;
};

function getReactApiKey() {
  if (window.bugsnag_react_contextual_key) {
    return window.bugsnag_react_contextual_key;
  }

  // Fall back to production key
  return 'a1c333e00bcc2a5bb56a9dd575fe9813';
}

function addFullStoryMetadata(event) {
  // Make sure FullStory object exists.
  try {
    if (window.FS && window.FS?.getCurrentSessionURL) {
      event.addMetadata('fullstory', {
        url: window.FS.getCurrentSessionURL(true),
      });
    }
  } catch (e) {
    console.error('Fullstory session URL not fetchable', e);
  }
}

function configureBugsnag(config) {
  // Make sure Bugsnag exists
  try {
    Bugsnag.addOnError((event) => {
      event.app.version = config.appVersion;
      event.setUser(
        config.bugsnag.user.id,
        undefined,
        config.bugsnag.user.name,
      );
      event.addMetadata('userInfo', {
        mcadmin: config.bugsnag.user.mcadmin,
        role: config.bugsnag.user.role,
        type: config.bugsnag.user.type,
      });
    });
  } catch (e) {
    Bugsnag.notify(
      new Error('@mc/config not available while trying to configure Bugsnag'),
    );
  }
}

Bugsnag.start({
  apiKey: getReactApiKey(),
  autoTrackSessions: false,
  autoDetectErrors: true,
  enabledReleaseStages: getEnabledStages(),
  releaseStage: __DEV__ ? STAGES.DEVELOPMENT : STAGES.PRODUCTION,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    addFullStoryMetadata(event);
  },
});

BugsnagPerformance.start({
  apiKey: getReactApiKey(),
  releaseStage: __DEV__ ? STAGES.DEVELOPMENT : STAGES.PRODUCTION,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export { ErrorBoundary as default, Bugsnag, configureBugsnag };
